.node-label {
    visibility: hidden;
}

.node-label:hover {
    visibility: visible;
}

.react-calendar {
    border: none !important;
    outline: none;
    border-radius: 8px;
    padding: 20px;
    font-family: 'Inter', sans-serif !important;
}

.react-calendar__month-view__weekdays__weekday {
    color: #878b94;
    font-weight: 400;
    text-decoration: none;
}

.react-calendar__tile--active {
    background-color: #f0e5ff !important;
    color: #741ded !important;
}

.react-calendar button {
    color: #878b94
}

.react-calendar__navigation button {
    color: #000;
    font-weight: 600;
}

.react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none;
    text-transform: initial;
}

.react-calendar__tile--now:enabled {
    background-color: #f0e5ff !important;
    color: #741ded !important;
}