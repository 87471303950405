.landingBody{
    overflow-x: clip !important;
}

.loginBox {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: Rubik, Arial, sans-serif !important;
}

.headerlogo {
    width: 164px;
    height: 24px;
}


.btnlg {
    font-family: Rubik, Arial, sans-serif !important;
    width: 180px;
    height: 48px;
    background-color: #00E676 !important;
}

.builderText {
    font-family: Rubik, Arial, sans-serif !important;
    width: 100%;
    height: 340px;
    background-color: #F3EBFF;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

}


.builderheading {
    font-family: Rubik, Arial, sans-serif !important;
    font-size: 56px !important;
    font-weight: 500 !important;
    line-height: 60px !important;
    padding: 20px !important;
    color: #3C3E49;
    margin-top: 60px !important;
    text-align: center;
}


.subtext {
    font-family: Rubik, Arial, sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 24px !important;

    color: #3C3E49;
    margin-bottom: 60px !important;
    text-align: center;

}

.subLogo {
    padding: 30px;
    height: 100px;
}

.sub {
    padding: 40px 130px 100px 130px;
    font-family: Rubik, Arial, sans-serif !important;
}



.keyTitle {
    font-family: Rubik, Arial, sans-serif !important;
    font-size: 32px !important;
    font-weight: bold !important;
    line-height: 36px;
    text-align: center;
    color: #3C3E49;
    
}

.boxKey {
    width: 100%;
    height: 25px;
    margin-top: 50px;
    font-size: 32px !important;
    font-weight: bold !important;
    text-align: center;
    font-family: Rubik, Arial, sans-serif !important;
}

.subBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.subBoxImg {
    height: 80px !important;
}

.featureImage{
    width: 80px;
    height: 80px
}

.dashboardHeading {
    font-family: Rubik, Arial, sans-serif !important;
    font-size: 24px;
    font-weight: bold !important;
    line-height: 32px !important;
    text-align: center;
    color: #3C3E49;
}

.dashboardSubtitle {
    font-family: Rubik, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #3C3E49;
}



/* Media Queries for Tablet and Mobile */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .loginBox {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    .headerlogo {
        width: 164px;
        height: 24px;
    }
    
    
    .btnlg {
        font-family: Rubik, Arial, sans-serif !important;
        width: 180px;
        height: 48px;
        background-color: #00E676 !important;
    }
    
    .builderText {
        font-family: Rubik, Arial, sans-serif !important;
        width: 100%;
        height: 340px;
        background-color: #F3EBFF;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    
    }
    
    
    .builderheading {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 56px !important;
        font-weight: 500 !important;
        line-height: 60px !important;
        padding: 20px !important;
        color: #3C3E49;
        margin-top: 60px !important;
        text-align: center;
    }
    
    
    .subtext {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    
        color: #3C3E49;
        margin-bottom: 60px !important;
        text-align: center;
    
    }
    
    .subLogo {
        padding: 30px;
        height: 100px;
    }
    
    .sub {
        padding: 40px 130px 100px 130px;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    
    
    .keyTitle {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 32px !important;
        font-weight: bold !important;
        line-height: 36px;
        text-align: center;
        color: #3C3E49;
        
    }
    
    .boxKey {
        width: 100%;
        height: 25px;
        margin-top: 50px;
        font-size: 32px !important;
        font-weight: bold !important;
        text-align: center;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    .subBox {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .subBoxImg {
        height: 80px !important;
    }
    
    .dashboardHeading {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 24px;
        font-weight: bold !important;
        line-height: 32px !important;
        text-align: center;
        color: #3C3E49;
    }
    
    .dashboardSubtitle {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #3C3E49;
    }
    
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .loginBox {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    .headerlogo {
        width: 164px;
        height: 24px;
    }
    
    
    .btnlg {
        font-family: Rubik, Arial, sans-serif !important;
        width: 180px;
        height: 48px;
        background-color: #00E676 !important;
    }
    
    .builderText {
        font-family: Rubik, Arial, sans-serif !important;
        width: 100%;
        height: 340px;
        background-color: #F3EBFF;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    
    }
    
    
    .builderheading {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 56px !important;
        font-weight: 500 !important;
        line-height: 60px !important;
        padding: 20px !important;
        color: #3C3E49;
        margin-top: 60px !important;
        text-align: center;
    }
    
    
    .subtext {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    
        color: #3C3E49;
        margin-bottom: 60px !important;
        text-align: center;
    
    }
    
    .subLogo {
        padding: 30px;
        height: 100px;
    }
    
    .sub {
        padding: 40px 130px 100px 130px;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    
    
    .keyTitle {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 32px !important;
        font-weight: bold !important;
        line-height: 36px;
        text-align: center;
        color: #3C3E49;
        
    }
    
    .boxKey {
        width: 100%;
        height: 25px;
        margin-top: 50px;
        font-size: 32px !important;
        font-weight: bold !important;
        text-align: center;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    .subBox {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .subBoxImg {
        height: 80px !important;
    }
    
    .dashboardHeading {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 24px;
        font-weight: bold !important;
        line-height: 32px !important;
        text-align: center;
        color: #3C3E49;
    }
    
    .dashboardSubtitle {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #3C3E49;
    }
    
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .loginBox {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    .headerlogo {
        width: 164px;
        height: 24px;
    }
    
    
    .btnlg {
        font-family: Rubik, Arial, sans-serif !important;
        width: 180px;
        height: 48px;
        background-color: #00E676 !important;
    }
    
    .builderText {
        font-family: Rubik, Arial, sans-serif !important;
        width: 100%;
        height: 340px;
        background-color: #F3EBFF;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    
    }
    
    
    .builderheading {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 56px !important;
        font-weight: 500 !important;
        line-height: 60px !important;
        padding: 20px !important;
        color: #3C3E49;
        margin-top: 60px !important;
        text-align: center;
    }
    
    
    .subtext {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    
        color: #3C3E49;
        margin-bottom: 60px !important;
        text-align: center;
    
    }
    
    .subLogo {
        padding: 30px;
        height: 100px;
    }
    
    .sub {
        padding: 40px 130px 100px 130px;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    
    
    .keyTitle {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 32px !important;
        font-weight: bold !important;
        line-height: 36px;
        text-align: center;
        color: #3C3E49;
    }
    
    .boxKey {
        width: 100%;
        height: 25px;
        margin-top: 50px;
        font-size: 32px !important;
        font-weight: bold !important;
        text-align: center;
        font-family: Rubik, Arial, sans-serif !important;
    }
    
    .subBox {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .subBoxImg {
        height: 80px !important;
    }
    
    .dashboardHeading {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 24px;
        font-weight: bold !important;
        line-height: 32px !important;
        text-align: center;
        color: #3C3E49;
    }
    
    .dashboardSubtitle {
        font-family: Rubik, Arial, sans-serif !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #3C3E49;
    }
    
}
@media only screen and (min-width: 429px) and (max-width: 768px) {

/* @media (max-width: 900px) { */
    /* .btnlg {
        width: 100px;
    }
    

    .loginBox {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 20px;
    }

    .btnLoginLink {
        margin-top: 30px !important;
        display: flex;
        justify-content: flex-end;
    } */


    .builderText {
        /* font-family: Rubik, Arial, sans-serif !important;
        width: 100%;
        height: 340px;
        background-color: #F3EBFF; */
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

    }


    .builderheading {
        font-size: 36px !important;
        text-align: center;
        line-height: 40px !important;
        /* font-family: Rubik, Arial, sans-serif !important;
        font-weight: 500 !important;
         */
        padding: 20px !important;
        /* color: #3C3E49; */
        /* margin-top: 60px !important; */
    }

    .subtext {
        font-size: 18px !important;
        text-align: center;
        /* font-family: Rubik, Arial, sans-serif !important;
        font-weight: 500 !important;
        line-height: 24px !important; */
        padding: 20px !important;
        /* color: #3C3E49; */
        margin-bottom: 60px !important;
    }

    /* .builderheading {
      font-size: 36px !important;
      line-height: 40px !important;
      margin-top: 30px !important;
    } */

    .keyTitle {
        font-size: 24px;
        line-height: 28px;
        padding: 5px 10px;
        /* Adjusted padding for smaller screens */
    }

    .boxKey {
        margin-top: 30px;
        text-align: center;
        /* Adjusted padding for smaller screens */
    }

    .sub {
        padding: 40px 44px 17px 45px;
        font-family: Rubik, Arial, sans-serif !important;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }

    .subBoxImg {
        height: 60px !important;
    }

    .dashboardHeading {
        font-size: 20px;
        line-height: 28px;
    }

    .dashboardSubtitle {
        font-size: 12px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 428px) {
    /* @media (max-width: 428px) { */
        /* .btnlg {
            width: 100px;
    } */
    

    .loginBox {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .btnLoginLink {
        margin-top: 30px !important;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;
    }


    .builderText {
        /* font-family: Rubik, Arial, sans-serif !important;
        width: 100%;
        height: 340px;
        background-color: #F3EBFF; */
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

    }


    .builderheading {
        font-size: 36px !important;
        text-align: center;
        line-height: 40px !important;
        /* font-family: Rubik, Arial, sans-serif !important;
        font-weight: 500 !important;
         */
        padding: 20px !important;
        /* color: #3C3E49; */
        /* margin-top: 60px !important; */
    }

    .subtext {
        font-size: 18px !important;
        text-align: center;
        /* font-family: Rubik, Arial, sans-serif !important;
        font-weight: 500 !important;
        line-height: 24px !important; */
        padding: 20px !important;
        /* color: #3C3E49; */
        margin-bottom: 60px !important;
    }

    /* .builderheading {
      font-size: 36px !important;
      line-height: 40px !important;
      margin-top: 30px !important;
    } */

    .keyTitle {
        font-size: 24px;
        line-height: 28px;
        padding: 5px 10px;
    }

    .boxKey {
        margin-top: 30px;
        text-align: center;
    }

    .sub {
        padding: 40px 44px 17px 45px;
        font-family: Rubik, Arial, sans-serif !important;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }

    .subBoxImg {
        height: 60px !important;
    }

    .dashboardHeading {
        font-size: 20px;
        line-height: 28px;
    }

    .dashboardSubtitle {
        font-size: 12px;
        line-height: 20px;
    }
}